import {_, Component, React, PropTypes} from 'components'; //eslint-disable-line
import Cookies from 'js-cookie';
import './footer.scss';

export default class Footer extends Component {
  state = {trackingAccepted: Cookies.get('gdprTrackingPermissionStatus') === 'opted-in'}

  toggleTrackingAccepted = () => {
    if (Cookies.get('gdprTrackingPermissionStatus') === 'opted-out') {
      this.setState({trackingAccepted: true});

      Cookies.set('gdprTrackingPermissionStatus', 'opted-in');
    }
    else {
      this.setState({trackingAccepted: false});

      Cookies.set('gdprTrackingPermissionStatus', 'opted-out');
    }
  }

  render() {
    return (
      <div className='footer' >
        <div className="careers-link"><a href="/page/careers">Careers</a></div>
        <div className="private-policy-link"><a target="_blank" rel='noopener noreferrer' href="https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/files/legal/henrybuilt/privacy.pdf">Privacy Policy</a></div>
        <div className='tracking-options' onClick={this.toggleTrackingAccepted}>opt {this.state.trackingAccepted ? 'out of' : 'in to'} cookie use</div>
        <div className="sf-credit">Site by <a href="https://www.symbolicframeworks.com" target="_blank" rel="noopener noreferrer">Symbolic Frameworks</a></div>
      </div>
    );
  }
}
