import {_, React} from 'components';
import Text from 'components/app/page/section/text/text.js';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'awards',
  title: 'Awards',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Awards'},
          `The judges have spoken. Unique tools. High-touch experience.`
        ]
      }
    },
    {
      layout: 'custom',
      heightAuto: true,
      render: () => {
        var awards = [
          {
            media: [{src: link('awards/architizer.png')}],
            award: 'Architizer A+ Product Award',
            date: '2024',
            body: {
              render: () => (
                <div>
                  The new Space Theory <a className='blue' href='/page/new-drawer-components' target='_blank'>new interior drawer components</a> were selected as a 2024 A+ Product Awards Jury Winner for product design. The Architizer A+ Product Awards are renowned as “the largest awards program focused on promoting and celebrating the year’s best architecture,” in addition to the year’s best products and interior design.
                </div>
              )
            },
            link: 'https://architizer.com/brands/space-theory/products/space-theorys-interior-drawer-components/'
          },
          {
            media: [{src: link('awards/red-dot.png')}],
            award: 'Red Dot Award',
            date: '2024',
            body: {
              render: () => (
                <div>
                  Space Theory’s <a className='blue' href='/page/new-drawer-components' target='_blank'>new interior drawer components</a> won a Red Dot Award for product design in the kitchen category. The Red Dot Award is a recognised international seal for excellence in design innovation. The awards program, “in search of good design,” is one of the world’s largest design competitions and has become established internationally as one of the most sought-after marks of quality design.
                </div>
              )
            },
            link: 'https://www.red-dot.org/'
          },
          {
            media: [{src: link('awards/german-design.png')}],
            award: 'German Design Award',
            date: '2024',
            body: {
              render: () => (
                <div>
                  The German Design Awards set international standards for innovative design developments and competitiveness on the global market. Space Theory’s <a className='blue' href='/page/new-drawer-components' target='_blank'>new interior drawer components</a> won a German Design Award in Excellent Product Design. The jury statement noted the components “can be removed individually [or] together with their contents and are conveniently located where they are needed. The wall brackets ensure a free work surface.”
                </div>
              )
            },
            link: 'https://www.german-design-award.com/en/the-winners/gallery/detail/47734-space-theorys-interior-drawer-components.html'
          },
          {
            media: [{src: link('awards/california-home-design.png')}],
            award: 'California Home + Design Award',
            date: '2024',
            body: {
              render: () => (
                <div>
                  The California Home + Design Awards honor excellence in the fields of interior design, architecture, landscape architecture, garden design, and product design. Space Theory won the Product Design category for its <a className='blue' href='/page/new-drawer-components' target='_blank'>new interior drawer components</a>. “Space Theory continues to rethink the performance of everyday spaces with the introduction of [these] interior drawer components,” writes editor-in-chief Lindsey Shook.
                </div>
              )
            },
            link: 'https://spacetheory-public.s3.amazonaws.com/website/pages/awards/califonia-home-design-award-2024.pdf'
          },
          {
            media: [{src: link('awards/iconic.png')}],
            award: 'Iconic Award',
            date: '2024',
            body: {
              render: () => (
                <div>
                  The Iconic Awards, run by the German Design Council, honor the best of the best from furniture to interior design, with a focus on smart, detailed solutions, sustainable product developments, and innovative design. Space Theory’s <a className='blue' href='/page/new-drawer-components' target='_blank'>new interior drawer components</a>, designed to transition easily from storage to serving, won in the product design category.
                </div>
              )
            },
            link: 'https://www.iconic-world.com/directory/space-theorys-interior-drawer-components'
          },
          {
            media: [{src: link('awards/if-design.png')}],
            award: 'iF Design Award',
            date: '2024',
            body: {
              render: () => (
                <div>
                  The iF Design Award is one the most prestigious international design awards, with more than 10,000 entries each year. The award emphasizes the global impact of design, and its judging involves a rigorous two-stage selection process conducted by renowned design experts. Space Theory’s <a className='blue' href='/page/new-drawer-components' target='_blank'>new interior drawer components</a>, suspendable on the Opencase wall system, won in the kitchen product design category.
                </div>
              )
            },
            link: 'https://ifdesign.com/en/winner-ranking/project/space-theorys-interior-drawer-components/633479'
          },
          {
            media: [{src: link('awards/inc-5000.png')}],
            award: 'Inc. 5000 Award',
            date: '2023',
            body: `Space Theory ranked among the top 500 and in the top 4% of Inc. magazine's fastest growing private companies in the U.S. Space Theory is the #3 fastest-growing company in Washington state, #2 fastest-growing company in the manufacturing industry, and #3 fastest-growing company in the Seattle-Tacoma-Bellevue area. The Inc. 5000 is a data-driven list that represents the fastest-growing private companies in America.`,
            link: 'https://www.inc.com/profile/space-theory'
          },
          {
            media: [{src: link('awards/if-design.png')}],
            award: 'iF Design Award',
            date: '2023',
            body: {
              render: () => (
                <div>
                  The iF Design Awards were founded in 1953 and have since become a global symbol of excellent design. Space Theory’s <a className='blue' href='/page/daylight' target='_blank'>Daylight Island</a> won a 2023 iF Design Award in the kitchen product category.
                </div>
              )
            },
            link: 'https://ifdesign.com/en/winner-ranking/project/daylight/570564'
          },
          {
            media: [{src: link('awards/red-dot.png')}],
            award: 'Red Dot Design Award',
            date: '2022',
            body: {
              render: () => (
                <div>
                  Space Theory’s <a className='blue' href='/page/daylight' target='_blank'>Daylight Island</a> was a winner in the Product Design category. Judged by a jury of experts in the field of product design, the renowned international design competition distinguishes brands for design excellence. Past winners of this prestigious award include BMW, Apple, Harmon Kardon, and Google.
                </div>
              )
            },
            link: 'https://www.red-dot.org/project/daylight-56032'
          },
          {
            media: [{src: link('awards/gray.png')}],
            award: 'GRAY Design Awards Finalist',
            date: '2022',
            body: {
              render: () => (
                <div>
                  The GRAY Design Awards recognize creative excellence in architecture, interiors and product design. Space Theory’s <a className='blue' href='/page/daylight' target='_blank'>Daylight Island</a> was a finalist in the Product Design category.
                </div>
              )
            },
            link: 'https://www.graymag.com/post/quick-list-2022-gray-awards-finalists'
          },
          {
            media: [{src: link('awards/architizer.png')}],
            award: 'Architizer A+ Product Awards Finalist',
            date: '2022',
            body: {
              render: () => (
                <div>
                  Space Theory’s <a className='blue' href='/page/daylight' target='_blank'>Daylight Island</a> was a finalist in the 2022 Architizer A+ Product Awards, which honor the world’s finest architecture and interior design. Architizer’s A+ Awards program is the largest international awards program for architecture and spaces.
                </div>
              )
            },
            link: 'https://architizer.com/brands/space-theory/products/daylight-island/'
          },
          {
            media: [{src: link('awards/if-design.png')}],
            award: 'iF Design Award',
            date: '2020',
            body: `The Space Theory kitchen system won a 2020 iF Design Award in the product design category. The prestigious competition, which has been around since 1953, received a record 7,298 entries from 56 nations across 7 disciplines. The judges hailed from 20 countries and met over 3 days in Hamburg to review all submissions.`,
            link: 'https://ifdesign.com/en/winner-ranking/project/space-theory-system/285550'
          },
          {
            media: [{src: link('awards/architizer.png')}],
            award: 'Architizer A+ Jury Winner',
            date: '2020',
            body: `The Architizer A+ Awards are the largest awards program focused on promoting and celebrating the date's best architecture and products. Space Theory, for its "custom kitchen product and systems solution," took home an A+ Jury Award.`,
            link: 'https://architizer.com/brands/henrybuilt/products/space-theory/'
          },
          {
            media: [{src: link('awards/good-design.png')}],
            award: 'Good Design Award',
            date: '2019',
            body: `Space Theory's kitchen design received a Good Design Award from The Chicago Athenaeum Museum of Architecture and Design and The European Centre for Architecture Art Design and Urban Studies. The prestigious awards, which were founded in Chicago in 1950 by Edgar Kaufmann Jr., Eero Saarinen, and Charles and Ray Eames, recognized over 900 product designs and graphics from 47 nations were honored by the program for their design excellence.`,
            link: 'https://www.chi-athenaeum.org/assets/pdf_archives/Gooddesign/GOOD_DESIGN_2019_MASTER_LIST.pdf'
          },
          {
            media: [{src: link('awards/gray.png')}],
            award: 'Gray Awards Wild Card Finalist',
            date: '2019',
            body: `Space Theory's internally developed design software was a Wild Card finalist at the Gray Awards, which celebrates the most innovative design of the Pacific Northwest, U.S. and Canada. The prestigious lineup of judges included Mette and Rolf Hay, behind design company HAY; Nicole Hollis of the eponymous interior design firm; and Yves Béhar, founder of the industrial design and branding firm Fuseproject.`,
            link: 'https://bustler.net/news/7607/2019-gray-awards-showcase-architecture-design-talent-of-the-pacific-northwest'
          },
          {
            media: [{src: link('awards/architects-newspaper.png')}],
            award: `Architect's Newspaper Honorable Mention `,
            date: '2019',
            body: `The Space Theory kitchen system was selected as one of two honorable mentions in the Kitchens category. The juried prize—determined by an esteemed group of design professionals and editors—assessed the products by their innovation, aesthetics, performance, and value.`,
            link: 'https://www.archpaper.com/2019/09/2019-best-of-products-awards/'
          },
        ];

        return (
          <div className='awards'>
            {/* <div className='awards-page-title'>Awards</div> */}
            <div className='awards'>
              {_.map(awards, (award, a) => <Text key={a} award={award}/>)}
            </div>
          </div>
        );
      }
    }
  ]
};
